import BaseRenderMixin from "@/components/base/BaseRenderMixin";
import {is_object} from "@/components/base/utils";
const BaseMultiTagComponent = () => import("@/components/base/tag/BaseMultiTagComponent")

export default {
    name: "BaseSingleSearchComponent",
    mixins: [BaseRenderMixin],
    props: {
        result: Object
    },
    methods: {
        make_element_tree(createElement, element_data, options = {},context = null) {
            let data = {}
            let el, props, attrs, style, children

            [el, props] = this.get_el_props(element_data, context)
            attrs = this.get_attrs(element_data)
            children = this.get_children(element_data, createElement)
            style = this.get_style(element_data)
            if (style) data.style = style
            if (props) data.props = props
            if (attrs) {
                data.attrs = attrs
            }

            for (let key in options) data[key] = options[key]

            return createElement(
                el,
                data,
                children
            )
        },
        get_el_props(element_data, context) {
            let el = element_data.tag
            let props = null
            if (element_data.attr && 'deepsel-multi-tag' in element_data.attr) {
                el = BaseMultiTagComponent
                props = {
                    component_data: element_data,
                    tag_data: context.tag_data
                }
            }
            return [el, props]
        },
        get_attrs(element_data) {
            if (element_data.attr) {
                let attrs = {}
                for (let key in element_data.attr) {
                    if (key === 'style' && is_object(element_data.attr[key])) continue
                    let value = element_data.attr[key];
                    attrs[key] = Array.isArray(value) ? value.join(" ") : value
                }

                if ('deepsel-search-result-image' in attrs) {
                    if (attrs.src) attrs.src = this.result.image
                } 
                if ('deepsel-search-result-title' in attrs) {
                    if (attrs.src) attrs.title = this.result.title
                }
                if ('deepsel-search-result-url' in attrs) {
                    if (this.result.type === 'page') {
                        attrs.href = `/${this.result.url}`
                    } else if (this.result.type === 'post') {
                        attrs.href = `/blog?blog=${this.result.url.substring(1)}`
                    }
                    if(this.$store.state.edit_mode) delete attrs.href  // disable in edit_mode
                }

                return attrs
            } else return null
        },
        get_style(element_data) {
            if (element_data.attr && is_object(element_data.attr.style)) {
                let origin = this.$store.state.origin;
                let style = JSON.parse(JSON.stringify(element_data.attr.style))

                if (style.backgroundImage) {
                    if ('deepsel-search-result-image' in element_data.attr) {
                        style.backgroundImage = `url(${this.result.image})`
                    } else if (
                        !style.backgroundImage.includes("https://") &&
                        !style.backgroundImage.includes("http://")
                    ) {
                        let background_img_path = style.backgroundImage
                            .replace('url(', '')
                            .replace(')', '')
                            .replace(/'/g, '')
                        if (background_img_path.slice(0, 1) !== "/") {
                            background_img_path = "/" + background_img_path;
                        }

                        style.backgroundImage = `url(${origin + background_img_path})`
                    }
                }
                return style
            } else return null
        },
        get_children(element_data, createElement) {
            let children = []
            let deepsel_text_data
            if (element_data.attr) {
                if ('deepsel-search-result-title' in element_data.attr) deepsel_text_data = this.result.title
                else if ('deepsel-search-result-subtitle' in element_data.attr) deepsel_text_data = this.result.subtitle
                else if ('deepsel-search-result-author' in element_data.attr) deepsel_text_data = this.result.author
                else if ('deepsel-search-result-date' in element_data.attr) deepsel_text_data = this.$moment(this.result.publish_date).format('llll')
                else if ('deepsel-search-result-category' in element_data.attr) deepsel_text_data = this.result.category
            }

            if (element_data.child) {
                for (let item of element_data.child) {
                    if (item.node === "element") {

                        let single_tag_children = false
                        if (item.attr && 'deepsel-multi-tag' in item.attr) {
                            if (single_tag_children) continue
                            single_tag_children = true
                            children.push(this.make_element_tree(createElement, item, {}, {tag_data: this.result.tag}))

                        } else {
                            children.push(this.make_element_tree(createElement, item));
                        }

                    } else if (item.node === "text") {
                        children.push(deepsel_text_data !== undefined ? (deepsel_text_data ? deepsel_text_data : '') : item.text)
                    }
                }
            }
            return children
        },
    }
}
